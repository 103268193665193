import React, { Component } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { domain } from "../url";
import FeatherIcon from "feather-icons-react";
import BookInfo from "./BookInfo";
import BarcodeReader from "react-barcode-reader";
import "./ScanBookPop.scss";

export class ScanBookPop extends Component {
  render() {
    const { visible, dataAccession } = this.props;
    console.log("librarydata", dataAccession);

    return (
      <div>
        <div
          className={`custom-popup ${visible ? "" : "display-none"} `}
          id="custom-popup"
          style={{ zIndex: this.props.bookPopIndex }}
        >
          <div className="popup">
            <div className="custom-popup-top">
              <div
                onClick={() => this.props.hideBookVisible()}
                className="close-popup float-right"
              >
                {" "}
                <FeatherIcon icon={"x"} />
              </div>
              <div className="form-group">
                <div className="col-md-10">
                  <div className="popup-ID">
                    <label className="mt-2">Book ID:</label>
                    <input
                      onChange={this.props.handleChange}
                      type="text"
                      name="bookId"
                      className="form-control"
                      value={this.props.bookId}
                    />
                    <button
                      onClick={() => {
                        this.props.showBookInfo();
                      }}
                      type="button"
                      className="btn btn-primary"
                    >
                      Show Info
                    </button>
                  </div>
                </div>
              </div>

              <hr className="hr-style" />

              {this.props.dataAccession?.length == 0
                ? ""
                : this.props.dataAccession.map((item) => {
                    return (
                      <div key={item.accession_no}>
                        <div className="popup-box">
                          <div className="book-details">
                            <BookInfo dataAccession={item} />
                            <div className="issued-details">
                              <div className="book-issuer">
                                <p className="m-0">
                                  This book was issued to {item?.name} on{" "}
                                  {item?.issue_daytoken}
                                </p>
                              </div>
                            </div>

                            <div className="fine-details">
                              <p>
                                Total Fine: <span className="red">Rs.0</span>
                              </p>
                            </div>

                            <div className="return-issue d-flex">
                              <button
                                type="button"
                                className="btn btn-secondary mr-3"
                              >
                                Return Book
                              </button>

                              <button
                                type="button"
                                className="btn btn-more mr-3"
                              >
                                Renew Book
                              </button>

                              <a className="btn btn-more" href>
                                <FeatherIcon icon={"more-vertical"} />
                              </a>
                            </div>

                            <div className="bottom-note mt-3">
                              <p>
                                Note* : The book will be returned by the above
                                scanned library ID.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScanBookPop;
