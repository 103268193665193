//@ts-check
import React, { useState, useEffect } from "react";
import "./Disbursement.scss";
import axios from "axios";
import { string } from "prop-types";

//const [id, setid]= useState("");
// response = axios.get("/annual_budget?budget_id="1})

function Disbursement(props) {
  const [data, setdata] = useState(null);
  const [val, setval] = useState(0);
  const [departmentValue, setDepartmentValues] = useState(() => {
    const tempObj = {};
    props.department.forEach(({ department_name }, index) => {
      tempObj[department_name] = 0;
    });
    return tempObj;
  });
  const [department, setdepartment] = useState({});

  const inputHandleOnChange = (e) => {
    setDepartmentValues((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const submit = (e) => {
    if (Number(data) - Number(val) > 0) {
      axios
        .post("/admin/save_disbursement", department)

        .then((res) => {
          toastr.success("Budget post successfully");
        });
    } else if (!data) {
      e.preventDefault();
      toastr.error("Please select on Budget");
    }
  };

  useEffect(() => {
    let localTotal = 0;
    for (let key in departmentValue) {
      if (Number(localTotal) + Number(departmentValue[key]) > Number(data)) {
        toastr.error(`Buget exceeded, Recalculated ${key} budget`);
        setDepartmentValues((p) => ({
          ...p,
          [key]: data - localTotal,
        }));
        return;
      }
      localTotal += Number(departmentValue[key]);
    }
    setval(localTotal);
  }, [departmentValue]);

  useEffect(() => {
    const tem = [];
    Object.keys(departmentValue).forEach((string) => {
      tem.push({ name: string, value: departmentValue[string] });
    });
    setdepartment(tem);
  }, [departmentValue]);

  const budgetHandleOnchange = (event) => {
    const value = event.target.value;
    axios
      .get(`/admin/annual_budget?budget_id=${value}`)
      .then((res) => {
        const result = Number(res.data.budget);
        setdata(result);
      })
      .catch((err) => console.log(err));
  };

  // const getData= (e, index)=>{
  //     const inputValue = e.target.value
  //     setval(inputValue);
  //     var subvalue = Number(data)-Number(inputValue)
  //     $('.department'+(index + 1)).val(subvalue);
  // }

  return (
    <div className="body disbursement-section card">
      <div className="card-body">
        <h2 className="title">Disbursement Form</h2>
        <h3 className="title">Budget</h3>
        <select
          name="Car"
          className="first_options"
          onChange={budgetHandleOnchange}
        >
          <option>Select</option>
          {props.capitals?.map((item, i) => (
            <option key={i} value={item.id}>
              {item.value}
            </option>
          ))}
        </select>
        <p style={{ marginTop: "30px" }}>
          {data && (
            <div style={{ display: "inline" }}>
              <div>
                <h3 style={{ marginRight: "30px" }}>
                  Total Budget: {Number(data)}{" "}
                </h3>
              </div>
              {Number(data) - Number(val) > 0 ? (
                <div>
                  <h3 style={{ marginRight: "10px" }}>
                    Remaining Budget: {Number(data) - Number(val)}{" "}
                  </h3>
                </div>
              ) : (
                <div>
                  <h3 style={{ marginRight: "10px" }}>Remaining Budget: 0 </h3>
                </div>
              )}
            </div>
          )}
        </p>
        <h3 className="title">Department</h3>
        <form onSubmit={submit}>
          {props.department?.map((item, index) => (
            <p>
              <label className="department">{item.department_name}</label>
              <input
                onChange={inputHandleOnChange}
                value={departmentValue[item.department_name] || ""}
                min="0"
                type="number"
                id={`name${index}`}
                name={item.department_name}
                className={`department${index}`}
              ></input>
            </p>
          ))}
          <button type="submit"> Save</button>
        </form>
      </div>
    </div>
  );
}

export default Disbursement;
