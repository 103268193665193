import React, { Component } from "react";
import BookissuePopup from "./BookissuePopup";
import FeatherIcon from "feather-icons-react";
import BookInfo from "./BookInfo";
import "./ScanLibrarycardPop.scss"

export class ScanLibrarycardPop extends Component {
  state = {
    fine: 0,
    focus: true,
    isImageError: false, //for image corrupt
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.bookRef = React.createRef();

    // this.inputRef.current.focus();
  }

  componentDidMount() {
    // Set focus when the component mounts
    this.inputRef.current.focus();
    console.log("Component did mount");
  }

  addFine = (value) => {
    this.setState({
      fine: this.state.fine + value,
    });
  };

  componentDidUpdate(prevProps) {
    // Check if the dependencies have changed

    if (event?.key === "b") {
      // Prevent default behavior (i.e., don't allow 'b' to be entered)
      event.preventDefault();
    }
    if (this.props.librarydata) {
      // Focus the input when dependencies change
      if (this.state.focus) {
        this.inputRef.current.focus();
      } else {
        this.bookRef.current.focus();
      }
    }
  }

  deductFine = (value) => {
    this.setState({
      fine: this.state.fine - value,
    });
  };

  render() {
    var temp = 0;
    const { visible, dataAccession } = this.props;
    console.log("librarydata", this.props.libraryId);
    console.log(`${window.location.origin}/uploads/jpt`);
    console.log("boom data", this.props.dataAccession);
    console.log("this.props.data value", this.props.data);

    return (
      <div
        className={`custom-popup ${visible ? "" : "display-none"} `}
        id="custom-popup"
        style={{ zIndex: this.props.libraryPopIndex }}
      >
        <div className="popup">
          <div className="custom-popup-top">
            <div
              onClick={() => this.props.hideLibraryVisible()}
              className="close-popup float-right"
            >
              <FeatherIcon icon={"x"} />
            </div>
            <div className="col-md-10">
              <h4 className="section-title"> User Information</h4>
              <div className="form-group">
                <div className="popup-ID">
                  <label className="mt-2">Library ID:</label>
                  <input
                    onChange={(event) => {
                      this.props.handleChange(event);
                      this.setState({
                        focus: true,
                      });
                    }}
                    type="text"
                    name="libraryId"
                    ref={this.inputRef}
                    className="form-control"
                    value={this.props.libraryId}
                    autoFocus
                    tabIndex="1"
                  />

                  <button
                    onClick={() => {
                      this.props.showMemberInfo();
                      this.setState({
                        fine: 0,
                        focus: false,
                      });
                      this.bookRef.current.focus();
                    }}
                    type="button"
                    className="btn btn-primary"
                    tabIndex="3"
                  >
                    Show Member Info
                  </button>
                </div>
              </div>
            </div>

            <hr className="hr-style" />
            <div className="row">
              <div className="col-md-4">
                <div className="popup-left">
                  <div className="student-information">
                    {this.props.libraryId != "" &&
                    this.props.libraryId != undefined ? (
                      <div>
                        <div className="student-name ">
                          {/* <img 
                        src={{this.state.data.members?.mem_photo}} alt=""
                      /> */}
                          {/* {this.props.data.members ? (
                            <img
                              className="photo-width"
                              src={
                                this.props.data.members.image_name
                                  ? `${window.location.origin}/uploads/${this.props.data.members.image_name}`
                                  : require("../../images/images.png")
                              }
                              alt="Photo"
                            />
                          ) : (
                            <img src={require("../../images/images.png")} />
                          )} */}

                          {this.props.data.members ? (
                            <>
                              <img
                                className="photo-width"
                                src={
                                  this.props.data.members.image_name
                                    ? `${window.location.origin}/uploads/${this.props.data.members.image_name}`
                                    : require("../../images/images.png")
                                }
                                alt="Photo"
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent looping if the fallback also fails
                                  e.target.src = require("../../images/images.png"); // Clear the source, allowing fallback to text
                                  // e.target.style.display = "none"; // Hide the image element if it fails to load
                                }}
                              />
                              
                          {/* 
                              {!this.props.data.members.image_name && (
                                <div
                                  className="fallback-initials"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#189548",
                                    borderRadius: "50%",
                                    fontSize: "100px",
                                    color: "#fff",
                                    textTransform: "uppercase",
                                    margin: "auto",
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.props.data.members.first_name.charAt(0).toUpperCase()}
                                </div>
                              )} */}
                            </>
                          ) : (
                            <img src={require("../../images/images.png")} alt="Photo" />
                          )}


                          {/* <img src={require("../../images/images.png")} /> */}
                          <div>
                            <h4>{`${
                              this.props.data.members?.first_name ?? ""
                            } ${this.props.data.members?.middle_name ?? ""} ${
                              this.props.data.members?.last_name ?? ""
                            }`}</h4>
                            <p>{this.props.data.members?.member_department_name}</p>
                          </div>
                        </div>
                        <div className="student-details">
                          <p>
                            <span className="student-detail-heading">
                              Member Type:
                            </span>
                            <span className="student-detail-content">
                              {this.props.data.members?.member_type}
                            </span>
                          </p>
                          {/* <p>
                            <span className="student-detail-heading">
                              {" "}
                              Level:{" "}
                            </span>
                            <span className="student-detail-content">
                              {this.props.data.members?.grade}
                            </span>
                          </p> */}
                          <p>
                            <span className="student-detail-heading">
                              {" "}
                              Batch:{" "}
                            </span>
                            <span className="student-detail-content">
                              {this.props.data.members?.batch_year}
                            </span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="no-member">
                        <img src={require("../../images/images.png")} />
                        <p>Scan Your Library Card</p>
                      </div>
                    )}
                    <hr className="hr-style" />
                    <div className="book-ID">
                      <label className="mt-2">Book ID:</label>

                      <div className="row">
                        <div className="col-md-6 pr-0">
                          <input
                            type="text"
                            name="bookId"
                            className="form-control"
                            ref={this.bookRef}
                            value={this.props.bookId}
                            onChange={(event) => {
                              this.props.onBookIdChange(event);
                              this.setState({
                                focus: false,
                              });
                            }}
                            tabIndex="2"
                          />
                        </div>

                        <div className="col-md-6 pr-0">
                          <button
                            onClick={() => {
                              this.props.cardScan();
                            }}
                            type="button"
                            className="btn btn-primary"
                            tabIndex="4"
                          >
                            Show Book Info
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        {this.props.dataAccession?.map((item, index) => {
                          if (!item.is_issued) {
                            temp += 1;
                            return (
                              <div className="mt-3 book-issue-div">
                                <div className="book-issue-area ">
                                  <div className="librarypop-issuebook d-flex s-between">
                                    <BookInfo
                                      dataAccession={item}
                                      showBookHistory={
                                        this.props.showBookHistory
                                      }
                                    />
                                    <div
                                      onClick={() =>
                                        this.props.deleteBook(index)
                                      }
                                      className="delete-book float-right"
                                    >
                                      <FeatherIcon
                                        icon={"trash-2"}
                                        style={{ width: "20px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div>
                        {temp > 0 ? (
                          <button
                            onClick={() => {
                              this.props.issueBook(this.props.dataAccession);
                            }}
                            type="button"
                            className="btn btn-primary issue-button mt-2"
                          >
                            Issue Book
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="popup-right">
                {this.props.data.issue_limit &&
                  <div className="row">
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                      <div class="wrap">
                        {/* <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Rental income</h4> */}
                        <span class="hind-font caption-12 c-dashboardInfo__count">{this.props?.data?.issue_limit}</span><span
                          class="hind-font caption-12 c-dashboardInfo__subInfo">Book Allowed for Issue</span>
                      </div>
                      <img src="https://i.imgur.com/b5hzrr1.png" alt="imgg" className="image-icon" />

                    </div>
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                      <div class="wrap">
                        {/* <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Rental income</h4> */}
                        <span class="hind-font caption-12 c-dashboardInfo__count">{this.props?.data?.books_taken_count}</span><span
                          class="hind-font caption-12 c-dashboardInfo__subInfo">Book Issued</span>
                          
                      </div>
                      <img src="https://i.imgur.com/Uqwaz7G.png" alt="imgg" className="image-icon" />

                    </div>

                  </div>
  }
                  
                  <h1>Issued History</h1>
                  <BookissuePopup
                    bookId={this.props.bookId}
                    books={this.props.books}
                    returnBook={this.props.returnBook}
                    renewBook={this.props.renewBook}
                    bookSelected={this.props.bookSelected}
                    discountChange={this.props.discountChange}
                    totalFine={this.props.totalFine}
                    isHighlighted={this.props.isHighlighted}
                    discount={this.props.discount}
                    addFine={this.addFine}
                    deductFine={this.deductFine}
                    fine={this.state.fine}

                    // dataAccession={this.props.dataAccession}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScanLibrarycardPop;
