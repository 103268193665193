// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("datatables.net-dt");
require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.html5.min.js");
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.min.js";
require("pdfmake/build/pdfmake");
import jsZip from "jszip";
window.JSZip = jsZip;

// import "css/style";
import $ from "jquery";
import "select2";
global.$ = jQuery;

global.toastr = require("toastr");
window.addEventListener("DOMContentLoaded", () => {
  $(".select2").select2({ width: "100%" });
});
import "bootstrap";
require("tree-multiselect/dist/jquery.tree-multiselect.min.js");

import flatpickr from "flatpickr";


require("packs/app-style-switcher");

// require("packs/feather.min");
require("packs/perfect-scrollbar.jquery.min");
require("packs/sidebarmenu");
require("packs/renderMenu");

const feather = require("feather-icons");
import "boxicons";
import "chart.js";

require("metismenu/dist/metisMenu.js");
require("metismenu/dist/metisMenu.css");

// import css
require("flatpickr/dist/flatpickr.css");
import "select2/dist/css/select2.css";
import "css/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context("../images", true);



function objectifyForm(formArray) {
  //serialize data function
  var returnArray = {};
  for (var i = 0; i < formArray.length; i++) {
    returnArray[formArray[i]["name"]] = formArray[i]["value"];
  }
  return returnArray;
}

window.objectifyForm = objectifyForm;
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(function () {
  feather.replace();

  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
  flatpickr("[data-behavior='flatpickrRange']", {
    mode: "range",
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    defaultDate: [
      formatDate(Date.now() - 7 * 24 * 60 * 60 * 1000),
      formatDate(Date.now()),
    ],
  });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  $("#test-select").treeMultiselect({ enableSelectAll: true });
  var shouldInit;
  $(".dataTbl tbody tr").each(function (i) {
    shouldInit = $(this).parent().parent().html().indexOf("SN") == -1;
    if (shouldInit) {
      $(this).prepend("<td></td>");
    }
  });
  $(".dataTbl thead tr,.dataTbl tfoot tr").each(function (i) {
    if (shouldInit) {
      $(this).prepend("<th>SN</th>");
    }
  });
  $(".dataTbl").DataTable({
    bPaginate: true,
    bFilter: true,
    bInfo: false,
    bAutoWidth: false,
    scrollX: true,
    dom: 'B<"clear">lfrtip',
    buttons: [
      {
        extend: "excel",
        text: '<i class="far fa-file-excel"></i> Excel',
        className:
          "btn waves-effect waves-light btn-rounded btn-outline-primary",
        init: function (api, node, config) {
          $(node).removeClass("dt-button buttons-excel buttons-html5");
        },
      },
      // {
      //   extend: "csv",
      //   text: '<i class="fas fa-file-csv"></i> CSV',
      //   className:
      //     "btn waves-effect waves-light btn-rounded btn-outline-warning",
      //   init: function (api, node, config) {
      //     $(node).removeClass("dt-button buttons-csv buttons-html5");
      //   },
      // },

      // {
      //   extend: "pdf",
      //   text: '<i class="far fa-file-pdf"></i> PDF',
      //   className:
      //     "btn waves-effect waves-light btn-rounded btn-outline-success",
      //   init: function (api, node, config) {
      //     $(node).removeClass("dt-button buttons-pdf buttons-html5 ");
      //   },
      // },
    ],
    columnDefs: [
      {
        searchable: false,
        orderable: false,
        targets: 0,
      },
    ],
    order: [[0, "asc"]],
    language: {
      search: "_INPUT_", // Removes the 'Search' field label
      searchPlaceholder: "Search", // Placeholder for the search box
    },
    pagingType: "simple_numbers",
    fnRowCallback: function (nRow, aData, iDisplayIndex) {
      $("td:first", nRow).html(iDisplayIndex + 1);
      return nRow;
    },
    lengthMenu: [10, 20, 50, 100],
    pageLength: 10,
  });
});

$(document).ready(function () {
  setTimeout(() => {
    $("#journalform").validate({
      rules: {
        journal_types: {
          required: true,
        },
      },
      messages: {
        Author: {
          required: "This field is required ",
        },
      },
    });
  }, 5000);

  setTimeout(() => {
    $("#bookform").validate({
      rules: {
        departments: {
          required: true,
        },
      },
      messages: {
        departments: {
          required: "This field is required ",
        },
      },
    });
  }, 5000);

  setTimeout(() => {
    $("#thesisform").validate({
      rules: {
        departments: {
          required: true,
        },
      },
      messages: {
        departments: {
          required: "This field id required ",
        },
      },
    });
  }, 5000);
});
