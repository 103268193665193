import React, { Component } from "react";

export class BookInfo extends Component {
  render() {
    return (
      <div className="book-name">
        <p className="mb-0">{this.props.dataAccession?.title}</p>
        <a
          href={`?accession_no=${this.props.dataAccession?.accession_no}`}
          target="_blank"
          // onDoubleClick={() => this.props.showBookHistory()}
        >
          {this.props.dataAccession?.accession_no}
        </a>
        {/* <p className="book-author">Author:</p> */}
      </div>
    );
  }
}

export default BookInfo;
