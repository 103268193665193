import React, { Component } from "react";
// import ScanBookPop from "./ScanBookPop";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ScanLibrarycardPop from "./ScanLibrarycardPop";
import BarcodeReader from "react-barcode-reader";
import axios from "axios";

const csrfToken = document.querySelector("[name=csrf-token]").content;
axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

export class Scan extends Component {
  state = {
    data: [],
    dataAccession: [],
    bookId: "",
    libraryId: "",
    result: "No result",
    libraryVisible: false,
    bookVisible: false,
    libraryPopIndex: 1000,
    bookPopIndex: 1000,
    bookList: [],
    returnList: [],
    totalFine: 0,
    isHighlighted: false,
  };

  arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr?.length) {
      var k = newIndex - arr?.length + 1;
      while (k--) {
        arr?.push(undefined);
      }
    }
    arr?.splice(newIndex, 0, arr?.splice(oldIndex, 1)[0]);
    return arr; // for testing
  };
  onBookIdChange = (event) => {
    this.setState({
      bookId: event.target.value,
    });
  };
  handleScan = (data, scan) => {
    if (data?.[0] == "B" || data?.[0] == "J" || data?.[0] == "T") {
      this.showLibraryVisible();
      if (scan == "scan") {
        this.setState({ bookId: data }, () => {
          this.cardScan();
        });
      }
    } else {
      this.showLibraryVisible();

      if (scan == "scan") {
        this.setState({ libraryId: data }, () => {
          this.cardScan();
        });
      }
    }
    document.body.style.overflow = "hidden";
  };

  showLibraryVisible = () => {
    this.setState({ libraryVisible: true });
  };

  hideLibraryVisible = () => {
    // this.setState({ libraryVisible: false }, () => {
    //   if (!this.state.libraryVisible) {
    //     this.setState({ data: [] });
    //   }
    // });
    this.setState({
      data: [],
      dataAccession: [],
      bookId: "",
      libraryId: "",
      result: "No result",
      libraryVisible: false,
      bookVisible: false,
      libraryPopIndex: 1000,
      bookPopIndex: 1000,
      bookList: [],
      returnList: [],
      discount: "",
    });
  };

  // showBookVisible = () => {
  //   this.setState({ bookVisible: true });
  // };

  // hideBookVisible = () => {
  //   this.setState({ bookVisible: false }, () => {
  //     this.setState({ dataAccession: [] });
  //   });
  // };

  increaseBookPopIndex = () => {
    this.setState((prevState) => ({
      bookPopIndex: prevState.libraryPopIndex + 100,
    }));
  };

  increaseLibraryPopIndex = () => {
    this.setState((prevState) => ({
      libraryPopIndex: prevState.bookPopIndex + 100,
    }));
  };

  handleError(err) {
    toastr.error(err);
  }

  showBookHistory = () => {
    const api = "/admin/book_hisory?accession_no=" + this.state.bookId;
    fetch(api).then((response) => response.json());
  };

  showBookInfo = () => {
    this.increaseBookPopIndex();
    const api = "/admin/accession_no?accession_no=" + this.state.bookId;
    fetch(api)
      .then((response) => response.json())
      .then((dataAccession) => {
        this.setState({ discount: dataAccession.discount });
        var temp = this.state.dataAccession;
        if (dataAccession.accession_no !== "unregistered") {
          if (
            temp.filter(
              (item) =>
                item.accession_no ==
                  dataAccession.accession_no[0].accession_no && !item.is_issued
            ).length === 0
          ) {
            temp.push(dataAccession.accession_no[0]);
          }

          this.setState(
            (prevState) => ({
              dataAccession: temp,
              libraryId:
                dataAccession.accession_no[0].library_id ?? prevState.libraryId,
            }),
            () => {
              if (this.state.libraryId != "") {
                this.showMemberInfo();
              }
            }
          );
        } else {
          alert("Book not registered");
        }
      });
  };

  showMemberInfo = () => {
    const api = "/admin/member_info?library_id=" + this.state.libraryId;
    var isHighlighted = false;
    fetch(api)
      .then((response) => response?.json())
      .then((result) => {
        this.setState({
          discount: result?.discount,
        });

        var books = result?.books;
        if (books != "noBook") {
          books?.forEach?.((item) => {
            item["discount"] = 0;
            item["payable_fine"] = 0;
          });

          if (this.state.bookId != "") {
            var oldIndex = books.findIndex(
              (book) => book.accession_no == this.state.bookId
            );
            if (oldIndex != -1) {
              isHighlighted = true;
            }
          }
          books = this.arrayMove(books, oldIndex, 0);
          console.log("filteredBooks", books);
        }

        const newData = {
          books: books === "noBook" ? [] : books,
          members:
            result?.member_data === "unregistered" || result?.member_data === ""
              ? []
              : JSON.parse(result?.member_data[0]?.members)?.[0],
          issue_limit: result?.issue_limit,
          books_taken_count: result?.books_taken_count,

        };
        if (result?.member_data === "unregistered") {
          alert("Unregistered Member");
        } else if (
          typeof result?.member_data === "string" &&
          result?.member_data?.trim() === ""
        ) {
          alert("No information");
        } else if (result?.books === "noBook") {
          alert("Unregistered Book");
        }
        this.setState(
          {
            data: newData,
            isHighlighted: isHighlighted,
          },
          () => {
            this.increaseLibraryPopIndex();
          }
        );
      });
  };

  cardScan = async () => {
    if (this.state.bookId != "" && this.state.data?.books != "noBook") {
      await this.showBookInfo();
    } else if (this.state.libraryId != "") {
      await this.showMemberInfo();
    }
  };

  issueBook = (bookList) => {
    if (this.state.libraryId != "") {
      axios
        .post(`/admin/book_issue`, {
          bookList: bookList,
          member_id: this.state.data?.members?.id,
        })
        .then((res) => {
          if (res.data.status) {
            toastr.success(res.data.message);
          } else {
            toastr.error(res.data.message);
          }
          this.hideLibraryVisible();
        });
    } else {
      alert("Please Scan Library Card");
    }
  };

  bookSelected = (e, index) => {
    var data = { ...this.state.data };
    data.books[index].selected = e.target.checked;
    this.setState({
      data,
    });
    this.updateTotalDueFine();
  };

  updateTotalDueFine = () => {
    const totalFine = this.state.data.books.reduce((acc, book) => {
      return parseFloat(
        book.fine -
          (parseFloat(book.payable_fine) > 0
            ? parseFloat(book.fine) - parseFloat(book.payable_fine)
            : 0)
      );
    }, 0);
    this.setState({ totalFine: totalFine });
  };

  discountChange = (e, index) => {
    var data = { ...this.state.data };
    data.books[index].discount = e.target.value;
    data.books[index].payable_fine =
      parseFloat(data.books[index]?.fine || 0) -
      parseFloat(e.target.value || 0);
    this.setState(
      {
        data,
      },
      () => {
        this.updateTotalDueFine();
      }
    );
  };

  returnBook = (returnList) => {
    axios
      .post(`/admin/return_book`, {
        returnList: returnList.filter((item) => item.selected),
        member_id: this.state.data?.members?.id,
      })
      .then((res) => {
        if (res.data.status) {
          toastr.success(res.data.message);
        } else {
          toastr.error(res.data.message);
        }
        var data = { ...this.state.data };
        data.books = returnList.filter((item) => !item.selected);
        this.setState({ data });
      });
  };

  renewBook = (renewList) => {
    axios
      .post(`/admin/renew_book`, {
        renewList: renewList.filter((item) => item.selected),
        member_id: this.state.data.members.id,
      })
      .then(({ data }) => {
        if (data.status === 200) {
          alert(data.message);
        } else {
          alert(data.message);
        }
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  deleteBook = (index) => {
    var temp = [...this.state.dataAccession];
    if (index !== -1) {
      temp.splice(index, 1);
      this.setState({ dataAccession: temp });
    }
  };

  render() {
    console.log(this.props.current_user.email);
    return (
      <div>
        <BarcodeReader
          // onError={this.handleError}
          onScan={(data) => this.handleScan(data, "scan")}
        />

        <KeyboardEventHandler
          handleKeys={["b"]}
          onKeyEvent={(data) => this.handleScan(data, "keypress")}
        />
        {this.props.current_user.branches_id !== null &&
        this.props.current_user.branches_id !== "" ? (
          <div>
            <ScanLibrarycardPop
              cardScan={this.cardScan}
              isHighlighted={this.state.isHighlighted}
              bookId={this.state.bookId}
              onBookIdChange={this.onBookIdChange}
              books={this.state.data.books}
              handleChange={this.handleChange}
              data={this.state.data}
              showBookInfo={this.showBookInfo}
              showMemberInfo={this.showMemberInfo}
              dataAccession={this.state.dataAccession}
              librarydata={this.state.libraryVisible}
              visible={this.state.libraryVisible}
              showLibraryVisible={this.showLibraryVisible}
              hideLibraryVisible={this.hideLibraryVisible}
              libraryId={this.state.libraryId}
              libraryPopIndex={this.state.libraryPopIndex}
              issueBook={this.issueBook}
              returnBook={this.returnBook}
              renewBook={this.renewBook}
              deleteBook={this.deleteBook}
              bookSelected={this.bookSelected}
              discountChange={this.discountChange}
              totalFine={this.state.totalFine}
              showBookHistory={this.showBookHistory}
              discount={this.state.discount}
            />

            {/* <ScanBookPop
            showBookInfo={this.showBookInfo}
            handleChange={this.handleChange}
            dataAccession={this.state.dataAccession}
            visible={this.state.bookVisible}
            showBookVisible={this.showBookVisible}
            hideBookVisible={this.hideBookVisible}
            bookId={this.state.bookId}
            bookPopIndex={this.state.bookPopIndex}
          /> */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Scan;
