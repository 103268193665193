import React, { Component } from "react";
import BookInfo from "./BookInfo";
export class BookissuePopup extends Component {
  state = {
    disableBtn: true,
  };
  render() {
    const { books } = this.props;
    console.log("books", books);
    console.log("books", this.props);
    return (
      <div>
        <div className="return-renew-area mb-4">
          {this.props.books?.length != 0 &&
          this.props.books != undefined &&
          this.props.books?.[0] != undefined ? (
            <div className="return-issue d-flex">
              <div className="fine-paid ">
                <div className="boxmargin">
                  <h4 className="due-date m-0 row-1">
                    Total Due Fine:
                    <p className="fine-amount m-0">                    
                      Rs. {this.props.fine}

                    </p>
                  </h4>
                </div>
              </div>

              <div className="renewandreturn">
                <button
                  onClick={() => {
                    this.props.renewBook(this.props.books);
                  }}
                  type="button"
                  className="btn btn-more mr-3 rnbook"
                  disabled={this.state.disableBtn}
                >
                  Renew Book
                </button>

                <button
                  onClick={() => {
                    this.props.returnBook(this.props.books);
                  }}
                  type="button"
                  className="btn btn-secondary mr-3 rbook"
                  disabled={this.state.disableBtn}
                >
                  Return Book
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>No Books Issued</p>
            </div>
          )}
        </div>
        <div className="issued-history">
          <div className="circulation-history mr-1">
            {books != "noBook" && this.props.books?.[0] != undefined
              ? books?.map((item, index) => {
                  return (
                    <div key={item?.accession_no}>
                      <label className="w-100" htmlFor={item?.accession_no}>
                        <div
                          className={
                            this.props.isHighlighted && index == 0
                              ? "popup-box p-0 highlightBookList"
                              : "popup-box p-0"
                          }
                        >
                          <div className="d-flex ">
                            <div className="checkbox-issue-history">
                              <input
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.setState({ disableBtn: false });
                                    this.props.addFine(item.fine)
                                  } else {
                                    this.setState({ disableBtn: true });
                                    this.props.deductFine(item.fine)
                                  }
                                  this.props.bookSelected(e, index);

                                }}
                                id={item?.accession_no}
                                type="checkbox"
                              />
                            </div>

                            <div className="book-details w-100">
                              <BookInfo dataAccession={item} />

                              <div className="d-flex">
                                <p className="bdate">
                                  <i class="fas fa-calendar-alt"></i>
                                  <span className="calendar-1">
                                    {" "}
                                    Issued On:
                                  </span>
                                  <span className="cal-2">
                                    {" "}
                                    {item?.issue_daytoken}
                                  </span>
                                </p>

                                <p className="bdate">
                                  <i class="fas fa-calendar-alt"></i>
                                  <span className="calendar-1">
                                    {" "}
                                    Return Date:
                                  </span>
                                  <span className="cal-2">
                                    {" "}
                                    {item?.return_daytoken}
                                  </span>
                                </p>
                              </div>

                              {/* <div className="status-not-returned mb-3">
                        Not Returned
                       </div> */}
                              <hr></hr>
                              <div className="fine-details d-flex ">
                                {item?.selected && this.props.discount ? (
                                  <div className="show-on-click d-flex">
                                    <div className="fine-discount mr-5">
                                      <p className="mb-0">DISCOUNT:</p>

                                      <input
                                        onChange={(e) => {
                                          this.props.discountChange(e, index);
                                        }}
                                        type="number"
                                        className="form-control discount-input"
                                        defaultValue={item?.discount || 0}
                                      />
                                    </div>
                                    <div className="vl"></div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="amount-block">
                                  <p className="amount">
                                    FINE: <span>Rs.{item?.fine || 0}</span>
                                  </p>
                                  <p className="amount ">
                                    TOTAL FINE:
                                    <span>
                                      Rs.
                                      {parseFloat(item?.fine || 0) -
                                        parseFloat(item?.discount || 0)}
                                    </span>
                                  </p>
                                </div>
                                {/* <button className="btn btn-more discount-button">
                              {" "}
                              <FeatherIcon icon={"more-vertical"} />
                            </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default BookissuePopup;
